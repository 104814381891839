@layer theme, base, components, utilities;
@import "tailwindcss/theme.css" layer(theme);
@import "tailwindcss/utilities.css" layer(utilities);

@import '../scss/element-classes.css' layer(utilities);
@import '../scss/spinners.css' layer(utilities);

@plugin '@tailwindcss/typography';
@plugin 'tailwindcss-primeui';

@theme {
    --font-sans: Helvetica Neue, ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji';
}

/*
  The default border color has changed to `currentColor` in Tailwind CSS v4,
  so we've added these compatibility styles to make sure everything still
  looks the same as it did with Tailwind CSS v3.

  If we ever want to remove these styles, we need to add an explicit border
  color utility to any element that depends on these defaults.
*/
@layer base {
    *,
    ::after,
    ::before,
    ::backdrop,
    ::file-selector-button {
        border-color: var(--color-gray-200, currentColor);
    }
}

:root {
    --html-background-color: var(--p-surface-0);
    /* PrimeFlex applies this color variable to the required * on form fields */
    --red-500: var(--p-red-500);
}

:root,
html.pi-theme-light {
    /* PrimeNg does not export the error color as a variable, so we define it ourselves. */
    /* https://github.com/primefaces/primeng-sass-theme/blob/17928d8be7a186e0fdf8940179f114c586dc9dad/themes/saga/_variables.scss */
    --text-color-error: var(--red-600);
    --text-color-success: var(--green-600);
    --border-error: var(--text-color-error);
    --surface-error: #ffcdd2;
    --surface-archived: #ffffe2;
    --text-link-color: var(--p-blue-600);
    --text-link-hover-color: var(--p-blue-800);
}

html {
    &.pi-theme-dark {
        --text-link-color: var(--p-blue-300);
        --text-link-hover-color: var(--p-blue-400);
        --html-background-color: var(--p-surface-950);
        --surface-archived: color-mix(in srgb, var(--p-yellow-500), var(--p-surface-950) 70%);
    }
}

html,
body {
    @apply font-sans;
    font-weight: 400;
    font-size: 16px;

    vertical-align: baseline;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    background-color: var(--html-background-color);
}

.max-width-standard {
    max-width: 500px;
}

.no-visibility {
    visibility: hidden !important;
}

.helper-text, .info-text {
    padding: 15px;
    background-color: var(--surface-50);
    border: 1px solid var(--p-content-border-color);

    border-radius: 5px;

    font-size: 0.9em;
    color: var(--text-color);
}

.helper-text {

    font-style: italic;
}

.section-icon {
    font-size: 1.5rem;
    color: var(--text-color);
}

.info-link {
    font-weight: 500;
    &.nowrap {
        white-space: nowrap;
    }
}

.help-overlay {
    max-width: 500px;
}

.hidden {
    display: none !important;
}

/* Make the link always blue and react to hover */
.link-color,
.link-color:visited {
    color: var(--text-link-color);
}

.link-color-hover-only:hover,
.link-color:hover,
.link-color:active {
    color: var(--text-link-hover-color);
}

/* makes content only visible to screen readers */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    word-wrap: normal;
    border: 0;
}

.title-text {
    font-weight: 600;
    font-size: 1.25rem;
    color: var(--text-color);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.p-dialog-mask {
    z-index: 100;
}

.field {
    @apply mb-4;
}
.field > label, .field > pay-form-control-label {
    @apply inline-block mb-2
}
.field.grid > label {
    @apply flex items-center;
}
.field > small, .field > pay-form-control-description:not(:empty) {
    @apply mt-1
}
.field.grid {
    @apply mt-0;
}
.field.grid .col-fixed,
.field.grid .col,
.field.grid .col-1,
.field.grid .col-2,
.field.grid .col-3,
.field.grid .col-4,
.field.grid .col-5,
.field.grid .col-6,
.field.grid .col-7,
.field.grid .col-8,
.field.grid .col-9,
.field.grid .col-10,
.field.grid .col-11,
.field.grid .col-12 {
    @apply py-0;
}
.field-checkbox {
    @apply mb-4 flex items-center;
}
.field-checkbox > label, .field-checkbox > pay-form-control-label {
    @apply ml-2 leading-none;
}
